
html{
	font-size: 62.5%;
}
body{	
	font-size: 1.6rem;
	font-family: 'Big Shoulders Display', sans-serif;
	font-weight: 400;

/*	font-family: 'Baloo';	*/
}
h1,h2,h3,h4,h5,h6,p{
	margin: 0;
	padding: 0;
}
ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
ul li a,a{
	text-decoration: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}
img {
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}
/*===========================  Start Page  ===========================*/
.page_main{
	background: url(../images/desktop.png) no-repeat scroll 0 0 / cover;
	padding: 20px 21px;
	height: 100vh;
	overflow-y: hidden;
}
.header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	max-width: 1200px;
	margin: auto;
}
.edit_resume{
	max-width: 520px;
}
.edit_resume img{
	width: 100%;
}
.btn_syle a {
	padding: 23px 15px 10px 15px;
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	color: #FFF;
}
.edit_resume{
	position: relative;
}
.btn_syle {
	position: absolute;
	right: 11px;
	bottom: 23px;
}


.text_paper {
	/* padding: 26px 8px 5px 41px; */
	/* background: url(../images/text-line.png) no-repeat scroll 0 0 / cover; */
	max-width: 540px;
	position: relative;
	z-index: 3;
}
.text_paper_title h3{
	font-size: 28px;
	line-height: 46px;
	font-family: 'Baloo';
	color: #525151;
	margin-left: 10px;
}
.text_paper_content, .text_paper_contents_all{
	position: relative;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

}
.text_paper_content p{
	position:absolute;
	word-wrap: break-word;
	top:0;
	bottom: 27%;
	font-size: 15px;
	line-height: 25px;
	font-family: 'Baloo';
	color: #525151;
	max-width: 478px;
}
.text_paper img{
	width: 100%;
}
.text_paper_contents_all {
	position: absolute;
	top: 29px;
	left: 46px;
}
.text_paper_items {
	/* padding: 0px 24px; */
	max-width: 346px;
	position: relative;
	z-index: 2;
	-webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.85);
	        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.85);
	border-radius: 0px 0px 24px 24px;
	overflow: hidden;
	padding: 16px 0px 0px 14px;
}
.tpibak{
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
.text_paper_items_back1 {
	top: -30px;
	left: -49px;
}
.tpibak.text_paper_items_back2 {
	position: absolute;
	top: 77px;
	left: 0px;
	max-width: 337px;
	z-index: -2;
}
.tpibak img{
	width: 100%;
}
.text_paper_items ul{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.text_paper_items ul li a{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin-right: 17px;
}
.text_paper_items ul li a img{
	height: 69px;
}
.text_paper_items ul li a span{
	font-weight: 700;
	font-size: 20px;
	line-height: 33px;
	color: #DEE8E7;
}
.text_paper_items_parent {
	max-width: 530px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	margin-top: -14px;
}
.text_paper_items_all{
	position: relative;
}
.text_paper_items_icon {
	position: absolute;
	top: 10px;
	left: -34px;
}
.text_paper_items_icon ul li a{
	display: inline-block;
}
.text_paper_items_icon ul li a img{
	width: 22px;
	height: 22px;
	margin-bottom: 11px;
}



/*===========================  End Page  ===========================*/

#paper-holder {
	font-family: 'Merriweather', serif;
	font-size: small;
	position: absolute;
	top: 5%;
	left: 4%;
	right: 3%;
	bottom: 5%;
	overflow: auto;
}
#paper-holder p{
	margin-bottom: 1em ;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.text_paper_items span {
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}
.text_paper_items ul li a img:hover {
	filter: drop-shadow(0 0 1rem rgb(147, 92, 15));
	scale: 1.1;
  }

.text_paper_items ul li a img{
	transition: 0.3s;
  }

.text_paper_items_icon ul li a img:hover {
	filter: drop-shadow(0 0 5px rgb(255, 255, 255));
	scale: 1.1;
  }

.text_paper_items_icon ul li a img{
	transition: 0.3s;
  }

.btn_syle a {
	transition: 0.3s;
}
.btn_syle a:hover {
	filter: drop-shadow(0 0 5px rgb(255, 255, 255));
}


#resume_content{
	position: absolute;
	font-family: 'Merriweather', serif;
	transition: 0.3s;
	overflow: scroll;
	font-size: x-small;
	top: 47%;
	left: 11%;
	right: 3%;
	bottom: 0%;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-mask-image: -webkit-gradient(linear, left top, 
    left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
/* #resume_content:hover{
	overflow: scroll;
} */
.shake {
    animation: shake 0.5s;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    10% { transform: translateX(-10px); }
    20% { transform: translateX(10px); }
    30% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    50% { transform: translateX(-10px); }
    60% { transform: translateX(10px); }
    70% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    90% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }



  .drum-viewport {
	position: absolute;
	font-family: 'Merriweather', serif;
	font-size: small;
	top: 0%;
	left: 0%;
	right: 0%;
	width: 100%;
	bottom: px;
	height: 8em;
	background: hsl(0, 0%, 90%);
	/* border: 5px solid hsl(0, 0%, 90%); */
	cursor: pointer;
	-webkit-mask-image: url(/assets/images/resume-paper.png);
    mask-image: url(/assets/images/resume-paper.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 364px;
  }
  
  .drum-viewport:focus {
	outline: none;
	background: hsl(0, 0%, 85%);
	border-color: hsl(0, 0%, 85%);
  }
  
  .drum-viewport::before,
  .drum-viewport::after {
	content: "";
	position: absolute;
	z-index: 1;
	left: 0;
	right: 0;
	height: 3em;
  }
  
  .drum-viewport::before {
	top: 0;
	background: linear-gradient(to bottom,
	#D3D3D3,
	rgba(217, 217, 217, 0));
  }
  
  .drum-viewport::after {
	bottom: 0;
	background: linear-gradient(to bottom,
	rgba(217, 217, 217, 0),
	#D3D3D3);
  }
  
  .drum-viewport:focus::before {
	background: linear-gradient(to bottom,
	#D3D3D3,
	rgba(217, 217, 217, 0));
  }
  
  .drum-viewport:focus::after {
	background: linear-gradient(to bottom,
	rgba(217, 217, 217, 0),
	#D3D3D3);
  }
  
  .drum-item {
	padding: 0.4em;
	background: white;
	color: hsl(210, 90%, 37%);
	text-align: center;
	font-weight: bold;
	text-indent: 3%;
  }
  
  .drum-item:not(:last-child) {
	border-bottom: 1px solid #FF0000;
  }

.iziModal-header-title {
	font-size: xx-large !important;
	font-weight: 800 !important;
	font-family: 'Big Shoulders Display', serif !important;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.iziModal-content {
	font-family: 'Merriweather', serif;
	font-size: small;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

figure {
	font-size: xx-small;
	display: inline-block;
	text-align: center;
	margin: 5px; /* adjust as needed */
	
  }
figure img {
	vertical-align: top;
	transition: 0.3s;
	margin-bottom: 10px;
}
figure img:hover {
	filter: drop-shadow(0 0 1rem rgb(0, 8, 255));
	scale: 1.1;
}





.header_right img{
	max-width: 700px;
	width: 100%;
}
.header_right_btn a {
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	color: #FFF;

	/* background: url(../images/continue-button.png) no-repeat scroll 0 0 / cover; */
}
.header_right_btn_main {
	display: flex;
	justify-content: flex-end;
	margin: -0.5px 12.8px 0px 0px;
}
.header_right_btn {
	text-align: center;
	position: relative;
	display: inline-block;
	padding: 0px 22px 0px 22px !important;
}
.header_right_btn_bagd{
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
/* .header_right_btn_bagd img {
	z-index: -2;
} */
.header_right {
	position: relative;
	z-index: 2;
}

.header_right:first-child {
	z-index: -5;
}

input[type=email], input[type=password]{
    display: block;
    margin: auto auto;
    width: 80%;
	align-self: left;
    margin-bottom: .5em;
    padding: .5em 0;
    border: none;
    border-bottom: 1px solid #eaeaea;
    /* padding-bottom: 1.25em; */
	background-color: rgb(225, 225, 225);
	padding-left: 1.25em;
	font-family: Arial, Helvetica, sans-serif;
	font-size: medium;
    color: #232323;
	text-align: left;
  }

.xp_button {
  padding: 4px;
  margin: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
.xp_submit {
	background-color: antiquewhite;
}

#password::placeholder, #email::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #757575;
	opacity: 1; /* Firefox */
  }

#google_login_icon, #facebook_login_icon {
	width:190px;
	height:45px;
	/* padding: 5px; */
	/* scale: 1.3; */
}

.join_button {
	--padding: var(--size-2);
	padding: var(--padding);
	border-radius: var(--size-4);
	text-decoration: none;
	color: transparent;
	position: relative;
	transition: background 0.2s;
  }
  
.join_button:hover {
	background: var(--gray-8);
  }

.settings_button {
	margin: 3px;
	padding: 3px;
}